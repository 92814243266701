@import url(font-awesome.min.css);
@import url("https://fonts.googleapis.com/css?family=Raleway:400, 700");
@import url("faixas.css");
/* Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  -webkit-text-size-adjust: none;
}

/* Box Model */

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Containers */

.container {
  margin-left: auto;
  margin-right: auto;
}

.container.\31 25\25 {
  width: 100%;
  max-width: 100em;
  min-width: 80em;
}

.container.\37 5\25 {
  width: 60em;
}

.container.\35 0\25 {
  width: 40em;
}

.container.\32 5\25 {
  width: 20em;
}

.container {
  width: 80em;
}

.container.contato {
  margin-bottom: 6em;
}

.container.contato h6 {
  /* margin-bottom: 18px; */
}

@media screen and (max-width: 1680px) {
  .container.\31 25\25 {
    width: 100%;
    max-width: 100em;
    min-width: 80em;
  }

  .container.\37 5\25 {
    width: 60em;
  }

  .container.\35 0\25 {
    width: 40em;
  }

  .container.\32 5\25 {
    width: 20em;
  }

  .container {
    width: 80em;
  }
}

@media screen and (max-width: 1280px) {
  .container.\31 25\25 {
    width: 100%;
    max-width: 81.25em;
    min-width: 65em;
  }

  .container.\37 5\25 {
    width: 48.75em;
  }

  .container.\35 0\25 {
    width: 32.5em;
  }

  .container.\32 5\25 {
    width: 16.25em;
  }

  .container {
    width: 65em;
  }
}

@media screen and (max-width: 980px) {
  .container.\31 25\25 {
    width: 100%;
    max-width: 112.5%;
    min-width: 90%;
  }

  .container.\37 5\25 {
    width: 67.5%;
  }

  .container.\35 0\25 {
    width: 45%;
  }

  .container.\32 5\25 {
    width: 22.5%;
  }

  .container {
    width: 90%;
  }
}

@media screen and (max-width: 736px) {
  .container.\31 25\25 {
    width: 100%;
    max-width: 112.5%;
    min-width: 90%;
  }

  .container.\37 5\25 {
    width: 67.5%;
  }

  .container.\35 0\25 {
    width: 45%;
  }

  .container.\32 5\25 {
    width: 22.5%;
  }

  .container {
    width: 90% !important;
  }
}

@media screen and (max-width: 480px) {
  .container.\31 25\25 {
    width: 100%;
    max-width: 112.5%;
    min-width: 90%;
  }

  .container.\37 5\25 {
    width: 67.5%;
  }

  .container.\35 0\25 {
    width: 45%;
  }

  .container.\32 5\25 {
    width: 22.5%;
  }

  .container {
    width: 90% !important;
  }
}

/* Grid */

.row {
  border-bottom: solid 1px transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.row > * {
  float: left;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.row:after,
.row:before {
  content: "";
  display: block;
  clear: both;
  height: 0;
}

.row.uniform > * > :first-child {
  margin-top: 0;
}

.row.uniform > * > :last-child {
  margin-bottom: 0;
}

.row.\30 \25 > * {
  padding: 0 0 0 0em;
}

.row.\30 \25 {
  margin: 0 0 -1px 0em;
}

.row.uniform.\30 \25 > * {
  padding: 0em 0 0 0em;
}

.row.uniform.\30 \25 {
  margin: 0em 0 -1px 0em;
}

.row > * {
  padding: 0 0 0 2em;
}

.row {
  margin: 0 0 -1px -2em;
}

.row.uniform > * {
  padding: 2em 0 0 2em;
}

.row.uniform {
  margin: -2em 0 -1px -2em;
}

.row.\32 00\25 > * {
  padding: 0 0 0 4em;
}

.row.\32 00\25 {
  margin: 0 0 -1px -4em;
}

.row.uniform.\32 00\25 > * {
  padding: 4em 0 0 4em;
}

.row.uniform.\32 00\25 {
  margin: -4em 0 -1px -4em;
}

.row.\31 50\25 > * {
  padding: 0 0 0 3em;
}

.row.\31 50\25 {
  margin: 0 0 -1px -3em;
}

.row.uniform.\31 50\25 > * {
  padding: 3em 0 0 3em;
}

.row.uniform.\31 50\25 {
  margin: -3em 0 -1px -3em;
}

.row.\35 0\25 > * {
  padding: 0 0 0 1em;
}

.row.\35 0\25 {
  margin: 0 0 -1px -1em;
}

.row.uniform.\35 0\25 > * {
  padding: 1em 0 0 1em;
}

.row.uniform.\35 0\25 {
  margin: -1em 0 -1px -1em;
}

.row.\32 5\25 > * {
  padding: 0 0 0 0.5em;
}

.row.\32 5\25 {
  margin: 0 0 -1px -0.5em;
}

.row.uniform.\32 5\25 > * {
  padding: 0.5em 0 0 0.5em;
}

.row.uniform.\32 5\25 {
  margin: -0.5em 0 -1px -0.5em;
}

.\31 2u,
.\31 2u\24 {
  width: 100%;
  clear: none;
  margin-left: 0;
}

.\31 1u,
.\31 1u\24 {
  width: 91.6666666667%;
  clear: none;
  margin-left: 0;
}

.\31 0u,
.\31 0u\24 {
  width: 83.3333333333%;
  clear: none;
  margin-left: 0;
}

.\39 u,
.\39 u\24 {
  width: 75%;
  clear: none;
  margin-left: 0;
}

.\38 u,
.\38 u\24 {
  width: 66.6666666667%;
  clear: none;
  margin-left: 0;
}

.\37 u,
.\37 u\24 {
  width: 58.3333333333%;
  clear: none;
  margin-left: 0;
}

.\36 u,
.\36 u\24 {
  width: 50%;
  clear: none;
  margin-left: 0;
}

.\35 u,
.\35 u\24 {
  width: 41.6666666667%;
  clear: none;
  margin-left: 0;
}

.\34 u,
.\34 u\24 {
  width: 33.3333333333%;
  clear: none;
  margin-left: 0;
}

.\33 u,
.\33 u\24 {
  width: 25%;
  clear: none;
  margin-left: 0;
}

.\32 u,
.\32 u\24 {
  width: 16.6666666667%;
  clear: none;
  margin-left: 0;
}

.\31 u,
.\31 u\24 {
  width: 8.3333333333%;
  clear: none;
  margin-left: 0;
}

.\31 2u\24 + *,
.\31 1u\24 + *,
.\31 0u\24 + *,
.\39 u\24 + *,
.\38 u\24 + *,
.\37 u\24 + *,
.\36 u\24 + *,
.\35 u\24 + *,
.\34 u\24 + *,
.\33 u\24 + *,
.\32 u\24 + *,
.\31 u\24 + * {
  clear: left;
}

.\-11u {
  margin-left: 91.66667%;
}

.\-10u {
  margin-left: 83.33333%;
}

.\-9u {
  margin-left: 75%;
}

.\-8u {
  margin-left: 66.66667%;
}

.\-7u {
  margin-left: 58.33333%;
}

.\-6u {
  margin-left: 50%;
}

.\-5u {
  margin-left: 41.66667%;
}

.\-4u {
  margin-left: 33.33333%;
}

.\-3u {
  margin-left: 25%;
}

.\-2u {
  margin-left: 16.66667%;
}

.\-1u {
  margin-left: 8.33333%;
}

@media screen and (max-width: 1680px) {
  .row > * {
    padding: 0 0 0 2em;
  }

  .row {
    margin: 0 0 -1px -2em;
  }

  .row.uniform > * {
    padding: 2em 0 0 2em;
  }

  .row.uniform {
    margin: -2em 0 -1px -2em;
  }

  .row.\32 00\25 > * {
    padding: 0 0 0 4em;
  }

  .row.\32 00\25 {
    margin: 0 0 -1px -4em;
  }

  .row.uniform.\32 00\25 > * {
    padding: 4em 0 0 4em;
  }

  .row.uniform.\32 00\25 {
    margin: -4em 0 -1px -4em;
  }

  .row.\31 50\25 > * {
    padding: 0 0 0 3em;
  }

  .row.\31 50\25 {
    margin: 0 0 -1px -3em;
  }

  .row.uniform.\31 50\25 > * {
    padding: 3em 0 0 3em;
  }

  .row.uniform.\31 50\25 {
    margin: -3em 0 -1px -3em;
  }

  .row.\35 0\25 > * {
    padding: 0 0 0 1em;
  }

  .row.\35 0\25 {
    margin: 0 0 -1px -1em;
  }

  .row.uniform.\35 0\25 > * {
    padding: 1em 0 0 1em;
  }

  .row.uniform.\35 0\25 {
    margin: -1em 0 -1px -1em;
  }

  .row.\32 5\25 > * {
    padding: 0 0 0 0.5em;
  }

  .row.\32 5\25 {
    margin: 0 0 -1px -0.5em;
  }

  .row.uniform.\32 5\25 > * {
    padding: 0.5em 0 0 0.5em;
  }

  .row.uniform.\32 5\25 {
    margin: -0.5em 0 -1px -0.5em;
  }

  .\31 2u\28xlarge\29,
  .\31 2u\24\28xlarge\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }

  .\31 1u\28xlarge\29,
  .\31 1u\24\28xlarge\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 0u\28xlarge\29,
  .\31 0u\24\28xlarge\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\39 u\28xlarge\29,
  .\39 u\24\28xlarge\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }

  .\38 u\28xlarge\29,
  .\38 u\24\28xlarge\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\37 u\28xlarge\29,
  .\37 u\24\28xlarge\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\36 u\28xlarge\29,
  .\36 u\24\28xlarge\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }

  .\35 u\28xlarge\29,
  .\35 u\24\28xlarge\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\34 u\28xlarge\29,
  .\34 u\24\28xlarge\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\33 u\28xlarge\29,
  .\33 u\24\28xlarge\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }

  .\32 u\28xlarge\29,
  .\32 u\24\28xlarge\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 u\28xlarge\29,
  .\31 u\24\28xlarge\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\31 2u\24\28xlarge\29 + *,
  .\31 1u\24\28xlarge\29 + *,
  .\31 0u\24\28xlarge\29 + *,
  .\39 u\24\28xlarge\29 + *,
  .\38 u\24\28xlarge\29 + *,
  .\37 u\24\28xlarge\29 + *,
  .\36 u\24\28xlarge\29 + *,
  .\35 u\24\28xlarge\29 + *,
  .\34 u\24\28xlarge\29 + *,
  .\33 u\24\28xlarge\29 + *,
  .\32 u\24\28xlarge\29 + *,
  .\31 u\24\28xlarge\29 + * {
    clear: left;
  }

  .\-11u\28xlarge\29 {
    margin-left: 91.66667%;
  }

  .\-10u\28xlarge\29 {
    margin-left: 83.33333%;
  }

  .\-9u\28xlarge\29 {
    margin-left: 75%;
  }

  .\-8u\28xlarge\29 {
    margin-left: 66.66667%;
  }

  .\-7u\28xlarge\29 {
    margin-left: 58.33333%;
  }

  .\-6u\28xlarge\29 {
    margin-left: 50%;
  }

  .\-5u\28xlarge\29 {
    margin-left: 41.66667%;
  }

  .\-4u\28xlarge\29 {
    margin-left: 33.33333%;
  }

  .\-3u\28xlarge\29 {
    margin-left: 25%;
  }

  .\-2u\28xlarge\29 {
    margin-left: 16.66667%;
  }

  .\-1u\28xlarge\29 {
    margin-left: 8.33333%;
  }
}

@media screen and (max-width: 1280px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }

  .row {
    margin: 0 0 -1px -1.5em;
  }

  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }

  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }

  .row.\32 00\25 > * {
    padding: 0 0 0 3em;
  }

  .row.\32 00\25 {
    margin: 0 0 -1px -3em;
  }

  .row.uniform.\32 00\25 > * {
    padding: 3em 0 0 3em;
  }

  .row.uniform.\32 00\25 {
    margin: -3em 0 -1px -3em;
  }

  .row.\31 50\25 > * {
    padding: 0 0 0 2.25em;
  }

  .row.\31 50\25 {
    margin: 0 0 -1px -2.25em;
  }

  .row.uniform.\31 50\25 > * {
    padding: 2.25em 0 0 2.25em;
  }

  .row.uniform.\31 50\25 {
    margin: -2.25em 0 -1px -2.25em;
  }

  .row.\35 0\25 > * {
    padding: 0 0 0 0.75em;
  }

  .row.\35 0\25 {
    margin: 0 0 -1px -0.75em;
  }

  .row.uniform.\35 0\25 > * {
    padding: 0.75em 0 0 0.75em;
  }

  .row.uniform.\35 0\25 {
    margin: -0.75em 0 -1px -0.75em;
  }

  .row.\32 5\25 > * {
    padding: 0 0 0 0.375em;
  }

  .row.\32 5\25 {
    margin: 0 0 -1px -0.375em;
  }

  .row.uniform.\32 5\25 > * {
    padding: 0.375em 0 0 0.375em;
  }

  .row.uniform.\32 5\25 {
    margin: -0.375em 0 -1px -0.375em;
  }

  .\31 2u\28large\29,
  .\31 2u\24\28large\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }

  .\31 1u\28large\29,
  .\31 1u\24\28large\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 0u\28large\29,
  .\31 0u\24\28large\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\39 u\28large\29,
  .\39 u\24\28large\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }

  .\38 u\28large\29,
  .\38 u\24\28large\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\37 u\28large\29,
  .\37 u\24\28large\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\36 u\28large\29,
  .\36 u\24\28large\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }

  .\35 u\28large\29,
  .\35 u\24\28large\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\34 u\28large\29,
  .\34 u\24\28large\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\33 u\28large\29,
  .\33 u\24\28large\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }

  .\32 u\28large\29,
  .\32 u\24\28large\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 u\28large\29,
  .\31 u\24\28large\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\31 2u\24\28large\29 + *,
  .\31 1u\24\28large\29 + *,
  .\31 0u\24\28large\29 + *,
  .\39 u\24\28large\29 + *,
  .\38 u\24\28large\29 + *,
  .\37 u\24\28large\29 + *,
  .\36 u\24\28large\29 + *,
  .\35 u\24\28large\29 + *,
  .\34 u\24\28large\29 + *,
  .\33 u\24\28large\29 + *,
  .\32 u\24\28large\29 + *,
  .\31 u\24\28large\29 + * {
    clear: left;
  }

  .\-11u\28large\29 {
    margin-left: 91.66667%;
  }

  .\-10u\28large\29 {
    margin-left: 83.33333%;
  }

  .\-9u\28large\29 {
    margin-left: 75%;
  }

  .\-8u\28large\29 {
    margin-left: 66.66667%;
  }

  .\-7u\28large\29 {
    margin-left: 58.33333%;
  }

  .\-6u\28large\29 {
    margin-left: 50%;
  }

  .\-5u\28large\29 {
    margin-left: 41.66667%;
  }

  .\-4u\28large\29 {
    margin-left: 33.33333%;
  }

  .\-3u\28large\29 {
    margin-left: 25%;
  }

  .\-2u\28large\29 {
    margin-left: 16.66667%;
  }

  .\-1u\28large\29 {
    margin-left: 8.33333%;
  }
}

@media screen and (max-width: 980px) {
  .row > * {
    padding: 0 0 0 1.5em;
  }

  .row {
    margin: 0 0 -1px -1.5em;
  }

  .row.uniform > * {
    padding: 1.5em 0 0 1.5em;
  }

  .row.uniform {
    margin: -1.5em 0 -1px -1.5em;
  }

  .row.\32 00\25 > * {
    padding: 0 0 0 3em;
  }

  .row.\32 00\25 {
    margin: 0 0 -1px -3em;
  }

  .row.uniform.\32 00\25 > * {
    padding: 3em 0 0 3em;
  }

  .row.uniform.\32 00\25 {
    margin: -3em 0 -1px -3em;
  }

  .row.\31 50\25 > * {
    padding: 0 0 0 2.25em;
  }

  .row.\31 50\25 {
    margin: 0 0 -1px -2.25em;
  }

  .row.uniform.\31 50\25 > * {
    padding: 2.25em 0 0 2.25em;
  }

  .row.uniform.\31 50\25 {
    margin: -2.25em 0 -1px -2.25em;
  }

  .row.\35 0\25 > * {
    padding: 0 0 0 0.75em;
  }

  .row.\35 0\25 {
    margin: 0 0 -1px -0.75em;
  }

  .row.uniform.\35 0\25 > * {
    padding: 0.75em 0 0 0.75em;
  }

  .row.uniform.\35 0\25 {
    margin: -0.75em 0 -1px -0.75em;
  }

  .row.\32 5\25 > * {
    padding: 0 0 0 0.375em;
  }

  .row.\32 5\25 {
    margin: 0 0 -1px -0.375em;
  }

  .row.uniform.\32 5\25 > * {
    padding: 0.375em 0 0 0.375em;
  }

  .row.uniform.\32 5\25 {
    margin: -0.375em 0 -1px -0.375em;
  }

  .\31 2u\28medium\29,
  .\31 2u\24\28medium\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }

  .\31 1u\28medium\29,
  .\31 1u\24\28medium\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 0u\28medium\29,
  .\31 0u\24\28medium\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\39 u\28medium\29,
  .\39 u\24\28medium\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }

  .\38 u\28medium\29,
  .\38 u\24\28medium\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\37 u\28medium\29,
  .\37 u\24\28medium\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\36 u\28medium\29,
  .\36 u\24\28medium\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }

  .\35 u\28medium\29,
  .\35 u\24\28medium\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\34 u\28medium\29,
  .\34 u\24\28medium\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\33 u\28medium\29,
  .\33 u\24\28medium\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }

  .\32 u\28medium\29,
  .\32 u\24\28medium\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 u\28medium\29,
  .\31 u\24\28medium\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\31 2u\24\28medium\29 + *,
  .\31 1u\24\28medium\29 + *,
  .\31 0u\24\28medium\29 + *,
  .\39 u\24\28medium\29 + *,
  .\38 u\24\28medium\29 + *,
  .\37 u\24\28medium\29 + *,
  .\36 u\24\28medium\29 + *,
  .\35 u\24\28medium\29 + *,
  .\34 u\24\28medium\29 + *,
  .\33 u\24\28medium\29 + *,
  .\32 u\24\28medium\29 + *,
  .\31 u\24\28medium\29 + * {
    clear: left;
  }

  .\-11u\28medium\29 {
    margin-left: 91.66667%;
  }

  .\-10u\28medium\29 {
    margin-left: 83.33333%;
  }

  .\-9u\28medium\29 {
    margin-left: 75%;
  }

  .\-8u\28medium\29 {
    margin-left: 66.66667%;
  }

  .\-7u\28medium\29 {
    margin-left: 58.33333%;
  }

  .\-6u\28medium\29 {
    margin-left: 50%;
  }

  .\-5u\28medium\29 {
    margin-left: 41.66667%;
  }

  .\-4u\28medium\29 {
    margin-left: 33.33333%;
  }

  .\-3u\28medium\29 {
    margin-left: 25%;
  }

  .\-2u\28medium\29 {
    margin-left: 16.66667%;
  }

  .\-1u\28medium\29 {
    margin-left: 8.33333%;
  }
}

@media screen and (max-width: 736px) {
  .row > * {
    padding: 0 0 0 1.25em;
  }

  .row {
    margin: 0 0 -1px -1.25em;
  }

  .row.uniform > * {
    padding: 1.25em 0 0 1.25em;
  }

  .row.uniform {
    margin: -1.25em 0 -1px -1.25em;
  }

  .row.\32 00\25 > * {
    padding: 0 0 0 2.5em;
  }

  .row.\32 00\25 {
    margin: 0 0 -1px -2.5em;
  }

  .row.uniform.\32 00\25 > * {
    padding: 2.5em 0 0 2.5em;
  }

  .row.uniform.\32 00\25 {
    margin: -2.5em 0 -1px -2.5em;
  }

  .row.\31 50\25 > * {
    padding: 0 0 0 1.875em;
  }

  .row.\31 50\25 {
    margin: 0 0 -1px -1.875em;
  }

  .row.uniform.\31 50\25 > * {
    padding: 1.875em 0 0 1.875em;
  }

  .row.uniform.\31 50\25 {
    margin: -1.875em 0 -1px -1.875em;
  }

  .row.\35 0\25 > * {
    padding: 0 0 0 0.625em;
  }

  .row.\35 0\25 {
    margin: 0 0 -1px -0.625em;
  }

  .row.uniform.\35 0\25 > * {
    padding: 0.625em 0 0 0.625em;
  }

  .row.uniform.\35 0\25 {
    margin: -0.625em 0 -1px -0.625em;
  }

  .row.\32 5\25 > * {
    padding: 0 0 0 0.3125em;
  }

  .row.\32 5\25 {
    margin: 0 0 -1px -0.3125em;
  }

  .row.uniform.\32 5\25 > * {
    padding: 0.3125em 0 0 0.3125em;
  }

  .row.uniform.\32 5\25 {
    margin: -0.3125em 0 -1px -0.3125em;
  }

  .\31 2u\28small\29,
  .\31 2u\24\28small\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }

  .\31 1u\28small\29,
  .\31 1u\24\28small\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 0u\28small\29,
  .\31 0u\24\28small\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\39 u\28small\29,
  .\39 u\24\28small\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }

  .\38 u\28small\29,
  .\38 u\24\28small\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\37 u\28small\29,
  .\37 u\24\28small\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\36 u\28small\29,
  .\36 u\24\28small\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }

  .\35 u\28small\29,
  .\35 u\24\28small\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\34 u\28small\29,
  .\34 u\24\28small\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\33 u\28small\29,
  .\33 u\24\28small\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }

  .\32 u\28small\29,
  .\32 u\24\28small\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 u\28small\29,
  .\31 u\24\28small\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\31 2u\24\28small\29 + *,
  .\31 1u\24\28small\29 + *,
  .\31 0u\24\28small\29 + *,
  .\39 u\24\28small\29 + *,
  .\38 u\24\28small\29 + *,
  .\37 u\24\28small\29 + *,
  .\36 u\24\28small\29 + *,
  .\35 u\24\28small\29 + *,
  .\34 u\24\28small\29 + *,
  .\33 u\24\28small\29 + *,
  .\32 u\24\28small\29 + *,
  .\31 u\24\28small\29 + * {
    clear: left;
  }

  .\-11u\28small\29 {
    margin-left: 91.66667%;
  }

  .\-10u\28small\29 {
    margin-left: 83.33333%;
  }

  .\-9u\28small\29 {
    margin-left: 75%;
  }

  .\-8u\28small\29 {
    margin-left: 66.66667%;
  }

  .\-7u\28small\29 {
    margin-left: 58.33333%;
  }

  .\-6u\28small\29 {
    margin-left: 50%;
  }

  .\-5u\28small\29 {
    margin-left: 41.66667%;
  }

  .\-4u\28small\29 {
    margin-left: 33.33333%;
  }

  .\-3u\28small\29 {
    margin-left: 25%;
  }

  .\-2u\28small\29 {
    margin-left: 16.66667%;
  }

  .\-1u\28small\29 {
    margin-left: 8.33333%;
  }
}

@media screen and (max-width: 480px) {
  .row > * {
    padding: 0 0 0 1.25em;
  }

  .row {
    margin: 0 0 -1px -1.25em;
  }

  .row.uniform > * {
    padding: 1.25em 0 0 1.25em;
  }

  .row.uniform {
    margin: -1.25em 0 -1px -1.25em;
  }

  .row.\32 00\25 > * {
    padding: 0 0 0 2.5em;
  }

  .row.\32 00\25 {
    margin: 0 0 -1px -2.5em;
  }

  .row.uniform.\32 00\25 > * {
    padding: 2.5em 0 0 2.5em;
  }

  .row.uniform.\32 00\25 {
    margin: -2.5em 0 -1px -2.5em;
  }

  .row.\31 50\25 > * {
    padding: 0 0 0 1.875em;
  }

  .row.\31 50\25 {
    margin: 0 0 -1px -1.875em;
  }

  .row.uniform.\31 50\25 > * {
    padding: 1.875em 0 0 1.875em;
  }

  .row.uniform.\31 50\25 {
    margin: -1.875em 0 -1px -1.875em;
  }

  .row.\35 0\25 > * {
    padding: 0 0 0 0.625em;
  }

  .row.\35 0\25 {
    margin: 0 0 -1px -0.625em;
  }

  .row.uniform.\35 0\25 > * {
    padding: 0.625em 0 0 0.625em;
  }

  .row.uniform.\35 0\25 {
    margin: -0.625em 0 -1px -0.625em;
  }

  .row.\32 5\25 > * {
    padding: 0 0 0 0.3125em;
  }

  .row.\32 5\25 {
    margin: 0 0 -1px -0.3125em;
  }

  .row.uniform.\32 5\25 > * {
    padding: 0.3125em 0 0 0.3125em;
  }

  .row.uniform.\32 5\25 {
    margin: -0.3125em 0 -1px -0.3125em;
  }

  .\31 2u\28xsmall\29,
  .\31 2u\24\28xsmall\29 {
    width: 100%;
    clear: none;
    margin-left: 0;
  }

  .\31 1u\28xsmall\29,
  .\31 1u\24\28xsmall\29 {
    width: 91.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 0u\28xsmall\29,
  .\31 0u\24\28xsmall\29 {
    width: 83.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\39 u\28xsmall\29,
  .\39 u\24\28xsmall\29 {
    width: 75%;
    clear: none;
    margin-left: 0;
  }

  .\38 u\28xsmall\29,
  .\38 u\24\28xsmall\29 {
    width: 66.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\37 u\28xsmall\29,
  .\37 u\24\28xsmall\29 {
    width: 58.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\36 u\28xsmall\29,
  .\36 u\24\28xsmall\29 {
    width: 50%;
    clear: none;
    margin-left: 0;
  }

  .\35 u\28xsmall\29,
  .\35 u\24\28xsmall\29 {
    width: 41.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\34 u\28xsmall\29,
  .\34 u\24\28xsmall\29 {
    width: 33.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\33 u\28xsmall\29,
  .\33 u\24\28xsmall\29 {
    width: 25%;
    clear: none;
    margin-left: 0;
  }

  .\32 u\28xsmall\29,
  .\32 u\24\28xsmall\29 {
    width: 16.6666666667%;
    clear: none;
    margin-left: 0;
  }

  .\31 u\28xsmall\29,
  .\31 u\24\28xsmall\29 {
    width: 8.3333333333%;
    clear: none;
    margin-left: 0;
  }

  .\31 2u\24\28xsmall\29 + *,
  .\31 1u\24\28xsmall\29 + *,
  .\31 0u\24\28xsmall\29 + *,
  .\39 u\24\28xsmall\29 + *,
  .\38 u\24\28xsmall\29 + *,
  .\37 u\24\28xsmall\29 + *,
  .\36 u\24\28xsmall\29 + *,
  .\35 u\24\28xsmall\29 + *,
  .\34 u\24\28xsmall\29 + *,
  .\33 u\24\28xsmall\29 + *,
  .\32 u\24\28xsmall\29 + *,
  .\31 u\24\28xsmall\29 + * {
    clear: left;
  }

  .\-11u\28xsmall\29 {
    margin-left: 91.66667%;
  }

  .\-10u\28xsmall\29 {
    margin-left: 83.33333%;
  }

  .\-9u\28xsmall\29 {
    margin-left: 75%;
  }

  .\-8u\28xsmall\29 {
    margin-left: 66.66667%;
  }

  .\-7u\28xsmall\29 {
    margin-left: 58.33333%;
  }

  .\-6u\28xsmall\29 {
    margin-left: 50%;
  }

  .\-5u\28xsmall\29 {
    margin-left: 41.66667%;
  }

  .\-4u\28xsmall\29 {
    margin-left: 33.33333%;
  }

  .\-3u\28xsmall\29 {
    margin-left: 25%;
  }

  .\-2u\28xsmall\29 {
    margin-left: 16.66667%;
  }

  .\-1u\28xsmall\29 {
    margin-left: 8.33333%;
  }
}

/* Basic */

@-ms-viewport {
  width: device-width;
}

body {
  -ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {
  html,
  body {
    min-width: 320px;
  }
}

body {
  background: #fff;
  padding-top: 6em;
  padding-top: 0 !important;
}

@media screen and (max-width: 980px) {
  body {
    padding-top: 2em;
  }
}

body.is-loading *,
body.is-loading *:before,
body.is-loading *:after {
  -moz-animation: none !important;
  -webkit-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
  -moz-transition: none !important;
  -webkit-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}

/* Type */

body {
  background-color: #fff;
  color: #666;
}

body,
input,
select,
textarea {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-size: 13pt;
  font-weight: 400;
  line-height: 2em;
}

@media screen and (max-width: 1680px) {
  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }
}

@media screen and (max-width: 1280px) {
  body,
  input,
  select,
  textarea {
    font-size: 11pt;
  }
}

@media screen and (max-width: 980px) {
  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }
}

@media screen and (max-width: 736px) {
  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }
}

@media screen and (max-width: 480px) {
  body,
  input,
  select,
  textarea {
    font-size: 12pt;
  }
}

a {
  text-decoration: underline;
  color: #daa520;
}

a:hover {
  text-decoration: none;
}

strong,
b {
  color: #484848;
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

p {
  margin: 0 0 2em 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #484848;
  font-weight: 700;
  line-height: 1em;
  margin: 0 0 1em 0;
  text-transform: uppercase;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 1.75em;
  line-height: 1.5em;
}

h3 {
  font-size: 1.35em;
  line-height: 1.5em;
}

h4 {
  font-size: 1.1em;
  line-height: 1.5em;
}

h5 {
  font-size: 0.9em;
  line-height: 1.5em;
}

h6 {
  font-size: 0.7em;
  line-height: 1.5em;
}

sub {
  font-size: 0.8em;
  position: relative;
  top: 0.5em;
}

sup {
  font-size: 0.8em;
  position: relative;
  top: -0.5em;
}

blockquote {
  border-left: solid 4px;
  font-style: italic;
  margin: 0 0 2em 0;
  padding: 0.5em 0 0.5em 2em;
}

code {
  background: rgba(144, 144, 144, 0.075);
  border-radius: 4px;
  border: solid 1px;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0.25em;
  padding: 0.25em 0.65em;
}

pre {
  -webkit-overflow-scrolling: touch;
  font-family: "Courier New", monospace;
  font-size: 0.9em;
  margin: 0 0 2em 0;
}

pre code {
  display: block;
  line-height: 1.75;
  padding: 1em 1.5em;
  overflow-x: auto;
}

hr {
  border: 0;
  border-bottom: solid 1px rgba(144, 144, 144, 0.25);
  margin: 2em 0;
}

hr.major {
  margin: 3em 0;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

/* Box */

.box {
  border-radius: 4px;
  border: solid 1px rgba(144, 144, 144, 0.25);
  margin-bottom: 2em;
  padding: 1.5em;
}

.box > :last-child,
.box > :last-child > :last-child,
.box > :last-child > :last-child > :last-child {
  margin-bottom: 0;
}

.box.alt {
  border: 0;
  border-radius: 0;
  padding: 0;
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  background-color: #323037;
  border-radius: 4px;
  color: #ffffff !important;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 700;
  line-height: 2em;
  padding: 0.8em 2.2em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover,
button:hover,
.button:hover {
  background-color: #3e3c45;
}

input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active,
button:active,
.button:active {
  background-color: #262429;
}

input[type="submit"].icon,
input[type="reset"].icon,
input[type="button"].icon,
button.icon,
.button.icon {
  padding-left: 1.35em;
}

input[type="submit"].icon:before,
input[type="reset"].icon:before,
input[type="button"].icon:before,
button.icon:before,
.button.icon:before {
  margin-right: 0.5em;
}

input[type="submit"].fit,
input[type="reset"].fit,
input[type="button"].fit,
button.fit,
.button.fit {
  display: block;
  margin: 0 0 1em 0;
  width: 100%;
}

input[type="submit"].small,
input[type="reset"].small,
input[type="button"].small,
button.small,
.button.small {
  font-size: 0.8em;
}

input[type="submit"].big,
input[type="reset"].big,
input[type="button"].big,
button.big,
.button.big {
  font-size: 1.35em;
}

input[type="submit"].disabled,
input[type="submit"]:disabled,
input[type="reset"].disabled,
input[type="reset"]:disabled,
input[type="button"].disabled,
input[type="button"]:disabled,
button.disabled,
button:disabled,
.button.disabled,
.button:disabled {
  -moz-pointer-events: none;
  -webkit-pointer-events: none;
  -ms-pointer-events: none;
  pointer-events: none;
  background-color: #666 !important;
  box-shadow: inset 0 -0.15em 0 0 rgba(0, 0, 0, 0.15);
  color: #fff !important;
  cursor: default;
  opacity: 0.25;
}

input[type="submit"].alt,
input[type="reset"].alt,
input[type="button"].alt,
button.alt,
.button.alt {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px rgba(144, 144, 144, 0.25);
  color: #484848 !important;
}

input[type="submit"].alt:hover,
input[type="reset"].alt:hover,
input[type="button"].alt:hover,
button.alt:hover,
.button.alt:hover {
  background-color: rgba(144, 144, 144, 0.075);
}

input[type="submit"].alt:active,
input[type="reset"].alt:active,
input[type="button"].alt:active,
button.alt:active,
.button.alt:active {
  background-color: rgba(144, 144, 144, 0.2);
}

input[type="submit"].alt.icon:before,
input[type="reset"].alt.icon:before,
input[type="button"].alt.icon:before,
button.alt.icon:before,
.button.alt.icon:before {
  color: #aaa;
}

input[type="submit"].special,
input[type="reset"].special,
input[type="button"].special,
button.special,
.button.special {
  background-color: #daa520;
  color: #ffffff !important;
}

input[type="submit"].special:hover,
input[type="reset"].special:hover,
input[type="button"].special:hover,
button.special:hover,
.button.special:hover {
  background-color: #b8860b;
}

input[type="submit"].special:active,
input[type="reset"].special:active,
input[type="button"].special:active,
button.special:active,
.button.special:active {
  background-color: #f21040;
}

@media screen and (max-width: 480px) {
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  button,
  .button {
  }
}

/* Feature */

.feature-grid {
  position: relative;
}

.feature-grid:after {
  clear: both;
  content: "";
  display: block;
}

.feature-grid .feature {
  display: inline-block;
  float: left;
  margin-bottom: 3em;
  width: 50%;
}

.feature-grid .feature .content {
  width: 60%;
}

.feature-grid .feature .image {
  width: 30%;
}

.feature-grid .feature .image img {
  display: inline;
  max-width: 100%;
}

.feature-grid .feature:nth-child(odd) .content,
.feature-grid .feature:nth-child(odd) .image {
  float: right;
  margin-right: 5%;
  text-align: right;
}

.feature-grid .feature:nth-child(even) .content,
.feature-grid .feature:nth-child(even) .image {
  float: left;
  margin-left: 5%;
  text-align: left;
}

@media screen and (max-width: 1280px) {
  .feature-grid .feature {
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 980px) {
  .feature-grid .feature {
    display: block;
    width: 100%;
  }

  .feature-grid .feature .content {
    width: 65%;
  }

  .feature-grid .feature .image {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 736px) {
  .feature-grid .feature {
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 480px) {
  .feature-grid .feature .content,
  .feature-grid .feature .image {
    display: block;
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: center !important;
    width: 100%;
  }

  .feature-grid .feature .image {
    margin-bottom: 1em;
  }
}

/* Form */

form {
  margin: 0 0 2em 0;
}

label {
  color: #484848;
  display: block;
  font-size: 0.9em;
  font-weight: 700;
  margin: 0 0 1em 0;
}

input[type="text"],
input[type="date"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
select,
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background: rgba(144, 144, 144, 0.075);
  border-radius: 4px;
  border: none;
  border: solid 1px rgba(144, 144, 144, 0.25);
  color: inherit;
  display: block;
  outline: 0;
  padding: 0 1em;
  text-decoration: none;
  width: 100%;
}

input[type="text"]:invalid,
input[type="password"]:invalid,
input[type="email"]:invalid,
select:invalid,
textarea:invalid {
  box-shadow: none;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
select:focus,
textarea:focus {
  border-color: #daa520;
  box-shadow: 0 0 0 1px #daa520;
}

.select-wrapper {
  text-decoration: none;
  text-decoration: none;
  display: block;
  position: relative;
}

.select-wrapper:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.select-wrapper:before {
  content: "\f078";
  color: rgba(144, 144, 144, 0.25);
  display: block;
  height: 2.75em;
  line-height: 2.75em;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 2.75em;
}

.select-wrapper select::-ms-expand {
  display: none;
}

input[type="text"],
input[type="date"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
select {
  height: 2.75em;
}

textarea {
  padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  display: block;
  float: left;
  margin-right: -2em;
  opacity: 0;
  width: 1em;
  z-index: -1;
}

input[type="checkbox"] + label,
input[type="radio"] + label {
  text-decoration: none;
  color: #666;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  padding-left: 2.4em;
  padding-right: 0.75em;
  position: relative;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

input[type="checkbox"] + label:before,
input[type="radio"] + label:before {
  background: rgba(144, 144, 144, 0.075);
  border-radius: 4px;
  border: solid 1px rgba(144, 144, 144, 0.25);
  content: "";
  display: inline-block;
  height: 1.65em;
  left: 0;
  line-height: 1.58125em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1.65em;
}

input[type="checkbox"]:checked + label:before,
input[type="radio"]:checked + label:before {
  content: "\f00c";
  background: #323037;
  border-color: #323037;
  color: #ffffff;
}

input[type="checkbox"]:focus + label:before,
input[type="radio"]:focus + label:before {
  border-color: #daa520;
  box-shadow: 0 0 0 1px #daa520;
}

input[type="checkbox"] + label:before {
  border-radius: 4px;
}

input[type="radio"] + label:before {
  border-radius: 100%;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #aaa !important;
}

:-moz-placeholder {
  opacity: 1;
  color: #aaa !important;
}

::-moz-placeholder {
  opacity: 1;
  color: #aaa !important;
}

:-ms-input-placeholder {
  opacity: 1;
  color: #aaa !important;
}

.formerize-placeholder {
  opacity: 1;
  color: #aaa !important;
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-transform: none !important;
}

.icon > .label {
  display: none;
}

/* Image */

.image {
  border-radius: 4px;
  border: 0;
  display: inline-block;
  position: relative;
}

.image img {
  border-radius: 4px;
  display: block;
}

.image.left,
.image.right {
  max-width: 40%;
}

.image.left img,
.image.right img {
  width: 100%;
}

.image.left {
  float: left;
  padding: 0 1.5em 1em 0;
  top: 0.25em;
}

.image.right {
  float: right;
  padding: 0 0 1em 1.5em;
  top: 0.25em;
}

.image.fit {
  display: block;
  margin: 0 0 2em 0;
  width: 100%;
}

.image.fit img {
  width: 100%;
}

.image.rounded {
  border-radius: 100%;
}

.image.rounded img {
  border: solid 0.5em rgba(144, 144, 144, 0.25);
  border-radius: 100%;
}

.image.captioned {
  border-radius: 0;
  margin-bottom: 4em;
}

.image.captioned img {
  border-radius: 0;
}

.image.captioned h3 {
  background-color: #fff;
  box-shadow: 0px 0.0375em 0.125em 0px rgba(0, 0, 0, 0.15);
  display: block;
  padding: 2em 1em;
}

@media screen and (max-width: 736px) {
  .image.captioned {
    margin-bottom: 2em;
  }

  .image.captioned h3 {
    padding: 1em;
  }
}

.image.main {
  display: block;
  margin: 0 0 3em 0;
  width: 100%;
}

.image.main img {
  width: 100%;
}

/* List */

ol {
  list-style: decimal;
  margin: 0 0 2em 0;
  padding-left: 1.25em;
}

ol li {
  padding-left: 0.25em;
}

ul {
  list-style: disc;
  margin: 0 0 2em 0;
  padding-left: 1em;
}

ul li {
  padding-left: 0.5em;
}

ul.alt {
  list-style: none;
  padding-left: 0;
}

ul.alt li {
  border-top: solid 1px rgba(144, 144, 144, 0.25);
  padding: 0.5em 0;
}

ul.alt li:first-child {
  border-top: 0;
  padding-top: 0;
}

ul.icons {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.icons li {
  display: inline-block;
  padding: 0 1em 0 0;
}

ul.icons li:last-child {
  padding-right: 0;
}

ul.icons li .icon:before {
  font-size: 2em;
}

ul.actions {
  cursor: default;
  list-style: none;
  padding-left: 0;
}

ul.actions li {
  display: inline-block;
  padding: 0 1em 0 0;
  vertical-align: middle;
}

ul.actions li:last-child {
  padding-right: 0;
}

ul.actions.small li {
  padding: 0 0.5em 0 0;
}

ul.actions.vertical li {
  display: block;
  padding: 1em 0 0 0;
}

ul.actions.vertical li:first-child {
  padding-top: 0;
}

ul.actions.vertical li > * {
  margin-bottom: 0;
}

ul.actions.vertical.small li {
  padding: 0.5em 0 0 0;
}

ul.actions.vertical.small li:first-child {
  padding-top: 0;
}

ul.actions.fit {
  display: table;
  margin-left: -1em;
  padding: 0;
  table-layout: fixed;
  width: calc(100% + 1em);
}

ul.actions.fit li {
  display: table-cell;
  padding: 0 0 0 1em;
}

ul.actions.fit li > * {
  margin-bottom: 0;
}

ul.actions.fit.small {
  margin-left: -0.5em;
  width: calc(100% + 0.5em);
}

ul.actions.fit.small li {
  padding: 0 0 0 0.5em;
}

@media screen and (max-width: 480px) {
  ul.actions {
    margin: 0 0 2em 0;
  }

  ul.actions li {
    padding: 1em 0 0 0;
    display: block;
    text-align: center;
    width: 100%;
  }

  ul.actions li:first-child {
    padding-top: 0;
  }

  ul.actions li > * {
    width: 100%;
    margin: 0 !important;
  }

  ul.actions li > *.icon:before {
    margin-left: -2em;
  }

  ul.actions.small li {
    padding: 0.5em 0 0 0;
  }

  ul.actions.small li:first-child {
    padding-top: 0;
  }
}

dl {
  margin: 0 0 2em 0;
}

dl dt {
  display: block;
  font-weight: 700;
  margin: 0 0 1em 0;
}

dl dd {
  margin-left: 2em;
}

/* Section/Article */

section.special,
article.special {
  text-align: center;
}

header p {
  color: #aaa;
  position: relative;
  margin: 0 0 1.5em 0;
  text-transform: uppercase;
}

header h2 + p {
  font-size: 1.25em;
  margin-top: -1em;
  line-height: 1.5em;
}

header h3 + p {
  font-size: 1.1em;
  margin-top: -0.8em;
  line-height: 1.5em;
}

header h4 + p,
header h5 + p,
header h6 + p {
  font-size: 0.9em;
  margin-top: -0.6em;
  line-height: 1.5em;
}

header.major {
  margin: 0 0 6em 0;
}

header.major h2 {
  font-size: 3em;
}

header.major h2,
header.major h3,
header.major h4,
header.major h5,
header.major h6 {
  display: inline-block;
  margin: 0 0 0.8em;
}

header.major p {
  font-size: 1.7em;
  margin-bottom: 0;
}

@media screen and (max-width: 1280px) {
  header.major {
    margin-bottom: 4em;
  }

  header.major h2 {
    font-size: 2.5em;
  }

  header.major p {
    font-size: 1.3em;
  }
}

@media screen and (max-width: 980px) {
  header.major h2 {
    font-size: 2em;
  }

  header.major p {
    font-size: 1.1em;
  }
}

@media screen and (max-width: 736px) {
  header.major {
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 480px) {
  header.major h2 {
    font-size: 1.75em;
  }
}

/* Table */

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
}

table {
  margin: 0 0 2em 0;
  width: 100%;
}

table tbody tr {
  border: solid 1px rgba(144, 144, 144, 0.25);
  border-left: 0;
  border-right: 0;
}

table tbody tr:nth-child(2n + 1) {
  background-color: rgba(144, 144, 144, 0.075);
}

table td {
  padding: 0.75em 0.75em;
}

table th {
  color: #484848;
  font-size: 0.9em;
  font-weight: 700;
  padding: 0 0.75em 0.75em 0.75em;
  text-align: center;
}

table thead {
  border-bottom: solid 2px rgba(144, 144, 144, 0.25);
}

table tfoot {
  border-top: solid 2px rgba(144, 144, 144, 0.25);
}

table.alt {
  border-collapse: separate;
}

table.alt tbody tr td {
  border: solid 1px rgba(144, 144, 144, 0.25);
  border-left-width: 0;
  border-top-width: 0;
  vertical-align: middle;
  text-transform: capitalize;
}

table.alt tbody tr td:first-child {
  border-left-width: 1px;
}

table.alt tbody tr:first-child td {
  border-top-width: 1px;
}

table.alt thead {
  border-bottom: 0;
}

table.alt tfoot {
  border-top: 0;
}

/* Wrapper */

body.is-touch .wrapper.style3 {
  background-attachment: scroll;
}

.wrapper {
  padding: 6em 0 4em 0;
}

.wrapper > .inner {
  margin: 0 auto;
  width: 60em;
}

.wrapper.style2 {
  background-color: #f6f6f6;
}

.wrapper.style3 {
  background-attachment: fixed;
  background-image: url("../images/overlay.png"), url("../images/banner.jpg");
  background-position: center center;
  background-size: cover;
}

.wrapper.style3 header.major {
  margin-bottom: 3em;
}

.wrapper.style3 header.major h2 {
  color: #ffffff;
}

@media screen and (max-width: 1280px) {
  .wrapper.style3 header.major {
    margin-bottom: 2em;
  }
}

.wrapper.style4 {
  background-color: #333;
}

.wrapper.style4 header.major h2 {
  color: #ffffff;
}

.wrapper.style4 h3 {
  color: #ccc;
  text-transform: initial;
}

.wrapper.style4 h4 {
  color: #eee;
}

@media screen and (max-width: 980px) {
  .wrapper {
    padding: 4em 0 2em 0;
  }
}

@media screen and (max-width: 736px) {
  .wrapper {
    padding: 3em 0 1em 0;
  }
}

@media screen and (max-width: 480px) {
  .wrapper {
    padding: 2em 0 0.1em 0;
  }
}

/* Banner */

body.is-touch #banner {
  background-attachment: scroll;
}

#banner {
  padding: 16em 2em 13em 2em;
  background-attachment: fixed;
  background-image: url("../images/overlay.png"), url("../images/banner.jpg");
  background-position: center top;
  background-size: cover;
  line-height: 1.75;
  text-align: center;
}

#banner:last-child {
  margin-bottom: 0;
}

#banner h2 {
  color: #ffffff;
  display: inline-block;
  font-size: 3.5em;
  line-height: 1.35;
  margin-bottom: 0.5em;
}

#banner p {
  color: #aaa;
  font-size: 1.5em;
  margin-bottom: 1.75em;
  text-transform: uppercase;
}

@media screen and (max-width: 1280px) {
  #banner {
    padding: 14em 2em 11em 2em;
  }

  #banner h2 {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 980px) {
  #banner {
    padding: 9em 0 7em 0;
  }
}

@media screen and (max-width: 736px) {
  #banner {
    padding: 6em 2em 6em 2em;
  }

  #banner br {
    display: none;
  }

  #banner h2 {
    font-size: 2.25em;
  }

  #banner p {
    font-size: 1.25em;
  }
}

@media screen and (max-width: 480px) {
  #banner {
    padding: 4em 2em 4em 2em;
  }
}

/* Main */

#main {
  padding-top: 1em;
}

/* Footer */

#footer {
  padding: 8em 0 6em 0;
  background: #f6f6f6;
  color: #aaa;
  text-align: center;
}

#footer a {
  -moz-transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
  color: #aaa;
  text-decoration: none;
}

#footer a:active,
#footer a:hover {
  color: #666;
}

#footer .icons {
  font-size: 1.5em;
}

#footer .icons li {
  padding-right: 2.5em;
}

#footer .icons li:last-child {
  padding-right: 0;
}

#footer .copyright {
  margin: 2em 0;
  padding: 0;
  text-align: center;
}

#footer .copyright li {
  border-left: solid 1px rgba(144, 144, 144, 0.25);
  display: inline-block;
  list-style: none;
  margin-left: 1.5em;
  padding-left: 1.5em;
}

#footer .copyright li:first-child {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

@media screen and (max-width: 980px) {
  #footer {
    padding: 6em 0 4em 0;
  }
}

@media screen and (max-width: 736px) {
  #footer {
    padding: 3em 0 2em 0;
  }

  #footer .icons {
    font-size: 1em;
  }
}

@media screen and (max-width: 480px) {
  #footer {
    padding: 3em 0 1em 0;
  }

  #footer .copyright li {
    border-left: 0;
    display: block;
    margin: 0;
    padding: 0;
  }
}

/*  */

.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  height: 840px;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

@media screen and (min-width: 800px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

.sem-treino {
  text-align: center;
  color: #f21040;
}

.ver-lista {
  margin-bottom: 20px;
}

#main table tbody tr td h5 {
  text-transform: capitalize;
}

.pages-container {
  margin-top: 56px;
}

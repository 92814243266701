.badge-faixa {
  padding: 0px;
  display: block;
  border: 1px solid #eeeeee;
  color: transparent;
  font-size: 8px;
}

.faixa-branca {
  background-color: #ffffff;
}

/* // Cinza */

.faixa-cinza {
  background: #929498;
}

.faixa-cinza-branca {
  background: repeating-linear-gradient(
    to right,
    #929498,
    #929498 33%,
    #ffffff 33%,
    #ffffff 67%,
    #929498 67%,
    #929498 100%
  );
}

.faixa-cinza-preta {
  background: repeating-linear-gradient(
    to right,
    #929498,
    #929498 33%,
    #000000 33%,
    #000000 67%,
    #929498 67%,
    #929498 100%
  );
}

/* // Amarela */
.faixa-amarela {
  background: #f6d407;
}

.faixa-amarela-branca {
  background: repeating-linear-gradient(
    to right,
    #f6d407,
    #f6d407 33%,
    #ffffff 33%,
    #ffffff 67%,
    #f6d407 67%,
    #f6d407 100%
  );
}

.faixa-amarela-preta {
  background: repeating-linear-gradient(
    to right,
    #f6d407,
    #f6d407 33%,
    #000000 33%,
    #000000 67%,
    #f6d407 67%,
    #f6d407 100%
  );
}

/* // laranja */
.faixa-laranja {
  background: #ee5a2b;
}

.faixa-laranja-branca {
  background: repeating-linear-gradient(
    to right,
    #ee5a2b,
    #ee5a2b 33%,
    #ffffff 33%,
    #ffffff 67%,
    #ee5a2b 67%,
    #ee5a2b 100%
  );
}

.faixa-laranja-preta {
  background: repeating-linear-gradient(
    to right,
    #ee5a2b,
    #ee5a2b 33%,
    #000000 33%,
    #000000 67%,
    #ee5a2b 67%,
    #ee5a2b 100%
  );
}

/* // verde */
.faixa-verde {
  background: #246737;
}

.faixa-verde-branca {
  background: repeating-linear-gradient(
    to right,
    #246737,
    #246737 33%,
    #ffffff 33%,
    #ffffff 67%,
    #246737 67%,
    #246737 100%
  );
}

.faixa-verde-preta {
  background: repeating-linear-gradient(
    to right,
    #246737,
    #246737 33%,
    #000000 33%,
    #000000 67%,
    #246737 67%,
    #246737 100%
  );
}

.faixa-azul {
  background: #3775be;
}

.faixa-roxa {
  background: #9c00da;
}

.faixa-marrom {
  background: #5d3811;
}

.faixa-preta {
  background: #010101;
}

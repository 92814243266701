#header {
  background-color: #000;
  padding: 4px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

#header .header-main {
  display: flex;
  justify-content: space-between;
}

#header .header-main h1 {
  line-height: 48px;
  font-size: 20px;
  margin: 0;
  padding: 0;
  font-weight: normal;
  text-transform: none;
  padding-left: 16px;
  color: #ccc;
}

#header .header-main .navPanelToggle {
  height: 48px;
  width: 48px;
  margin: 0;
  padding: 16px;
  line-height: 16px;
}

#header ul {
  margin: 16px 0;
  padding: 0;
  list-style: none;
}

#header ul li {
  text-decoration: none;
  display: block;
  padding: 8px 16px;
}

#header ul li a {
  text-decoration: none;
}